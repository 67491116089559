import React from 'react';
import { Link } from 'react-router-dom';
import firebase from './firebase';

const Navbar = ({ user }) => {
  const handleSignOut = () => {
    firebase.auth().signOut();
  };

  return (
    <nav className="navbar">
      <div className="logo-container">
        <Link to="/">Photo Showcase</Link>
      </div>
      <div className="buttons-container">
        {user ? (
          <>
            <div className="profile-initials">{user.displayName && user.displayName.charAt(0).toUpperCase()}</div>
            <Link to="/post" className="nav-button">Post</Link>
            <Link to="/settings" className="nav-button">Settings</Link>
            <Link className="nav-button" onClick={handleSignOut}>Sign Out</Link>
          </>
        ) : (
          <>
            <Link to="/signup" className="nav-button">
              Sign Up
            </Link>
            <Link to="/login" className="nav-button">
              Login
            </Link>
          </>
        )}
      </div>
    </nav>
  );
};

export default Navbar;