import React from 'react';

const Photo = ({ photo }) => {
  return (
    <div className="photo">
      <img src={photo.imageURL} alt={photo.caption} />
      <p>{photo.caption}</p>
    </div>
  );
};

export default Photo;