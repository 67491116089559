import React from 'react';
import Gallery from './Gallery';

const Home = () => {
  return (
    <div>
      <h3>Welcome to the Photo Showcase!</h3>
      <p>This is the home page.</p>
      <br />
      <Gallery />
    </div>
  );
};

export default Home;