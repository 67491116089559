import React, { useState, useEffect } from 'react';
import firebase from './firebase';

const UserSettings = ({ user }) => {
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [aboutMe, setAboutMe] = useState('');
  const [profilePicture, setProfilePicture] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);

  useEffect(() => {
    if (user) {
      const db = firebase.firestore();
      const userRef = db.collection('users').doc(user.uid);

      userRef
        .get()
        .then((doc) => {
          if (doc.exists) {
            const userData = doc.data();
            setEmail(userData.email || user.email || '');
            setUsername(userData.username || user.username || '');
            setAboutMe(userData.aboutMe || user.aboutMe || '');
          } else {
            setEmail(user.email || '');
            setUsername(user.username || '');
            setAboutMe(user.aboutMe || '');
          }
        })
        .catch((error) => {
          console.log('Error getting user data:', error);
        });
    }
  }, [user]);

  const handleProfilePictureChange = (e) => {
    if (e.target.files[0]) {
      setProfilePicture(e.target.files[0]);
    }
  };

  const handleProfilePictureUpload = () => {
    if (profilePicture) {
      const storageRef = firebase.storage().ref().child(`profilePictures/${user.uid}`);
      const uploadTask = storageRef.put(profilePicture);

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setUploadProgress(progress);
        },
        (error) => {
          console.log(error);
        },
        () => {
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            // Save the downloadURL to Firestore or update user profile
            // with the downloadURL as the profile picture field
            console.log(downloadURL);
            setUploadProgress(0);
          });
        }
      );
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      // Check if the new username is available
      const db = firebase.firestore();
      const usersRef = db.collection('users');
  
      // Perform a query to check if the username already exists
      const usernameQuery = await usersRef.where('username', '==', username).get();
  
      if (!usernameQuery.empty) {
        console.log('The username is already taken. Please choose a different one.');
        return;
      }
  
      // Update user profile fields in Firestore
      const currentUser = firebase.auth().currentUser;
      const uid = currentUser.uid;
      const userRef = db.collection('users').doc(uid);
  
      await userRef.set(
        {
          email: email,
          username: username,
          aboutMe: aboutMe,
        },
        { merge: true }
      );
  
      console.log('User profile updated successfully');
    } catch (error) {
      console.error('Error updating user profile:', error);
    }
  }; 

  return (
    <div className="user-settings">
      <h2>User Settings</h2>
      <form onSubmit={handleSubmit}>
        <label htmlFor="email">Email</label>
        <input
          type="email"
          id="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <label htmlFor="username">Username</label>
        <input
          type="text"
          id="username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <label htmlFor="aboutMe">About Me</label>
        <textarea
          id="aboutMe"
          value={aboutMe}
          onChange={(e) => setAboutMe(e.target.value)}
        />
        <label htmlFor="profilePicture">Profile Picture</label>
        <input
          type="file"
          id="profilePicture"
          accept="image/*"
          onChange={handleProfilePictureChange}
        />
        {profilePicture && (
          <div>
            <button type="button" onClick={handleProfilePictureUpload}>
              Upload Profile Picture
            </button>
            <progress value={uploadProgress} max="100" />
          </div>
        )}
        <p></p>
        <button type="submit">Save Changes</button>
      </form>
    </div>
  );
};

export default UserSettings;