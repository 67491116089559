import React, { useState } from 'react';
import firebase from './firebase';

const PostForm = () => {
  const [image, setImage] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);  
  const [fileSizeError, setFileSizeError] = useState('');

  const handleImageChange = (e) => {
    if (e.target.files[0]) {
      const selectedImage = e.target.files[0];
      const fileSizeInMB = selectedImage.size / (1024 * 1024);
      if (fileSizeInMB > 10) {
        setFileSizeError('Image size exceeds the maximum limit of 10MB');
        setImage(null);
      } else {
        setImage(selectedImage);
        setFileSizeError('');
      }
    }
  };

  const handleImageUpload = async () => {
    if (image) {
      const storageRef = firebase.storage().ref().child(`images/${image.name}`);
      const uploadTask = storageRef.put(image);

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setUploadProgress(progress);
        },
        (error) => {
          console.log(error);
        },
        async () => {
          try {
            const downloadURL = await uploadTask.snapshot.ref.getDownloadURL();
            const currentUser = firebase.auth().currentUser;
            const username = currentUser.displayName;
            const imageName = image.name;

            // Check if "images" collection exists in Firestore
            const imagesCollection = firebase.firestore().collection('images');
            const doc = await imagesCollection.limit(1).get();

            if (doc.empty) {
              // Create "images" collection if it doesn't exist
              await imagesCollection.doc();
            }

            // Save the image details in Firestore
            await imagesCollection.add({
              imageURL: downloadURL,
              imageName: imageName,
              username: username,
            });

            console.log('Image details saved to Firestore');
          } catch (error) {
            console.log('Error saving image details:', error);
          }
          setUploadProgress(0);
        }
      );
    }
  };

  return (
    <div className="post-form">
      <h2>Upload an Image</h2>
      <input type="file" accept="image/*" onChange={handleImageChange} />
      {fileSizeError && <p className="error">{fileSizeError}</p>}
      <button onClick={handleImageUpload}>Upload Image</button>
      {uploadProgress > 0 && <progress value={uploadProgress} max="100" />}
    </div>
  );  
};

export default PostForm;