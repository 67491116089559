import React, { useState } from 'react';
import firebase from './firebase';

const Signup = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [username, setUsername] = useState('');
  const [error, setError] = useState(null);

  const handleSignup = async (e) => {
    e.preventDefault();

    if (!email || !password || !username) {
      setError('Please fill in all fields.');
      return;
    }

    try {
      // Check if the username already exists in Firestore
      const db = firebase.firestore();
      const usersRef = db.collection('users');
      const usernameQuery = await usersRef.where('username', '==', username).get();

      if (!usernameQuery.empty) {
        setError('The username is already taken. Please choose a different one.');
        return;
      }

      // Create the user in Firebase Authentication
      const userCredential = await firebase.auth().createUserWithEmailAndPassword(email, password);

      // Update the user's display name
      await userCredential.user.updateProfile({
        displayName: username,
      });

      // Store the user's email and username in Cloud Firestore
      await db.collection('users').doc(userCredential.user.uid).set({
        email: email,
        username: username,
      });
    } catch (error) {
      setError(error.message);
      console.error('Error storing user data:', error);
    }
  };

  return (
    <div className="signup-form">
      <h2>Sign Up</h2>
      <form onSubmit={handleSignup}>
        <input
          type="text"
          placeholder="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <br />
        <button type="submit">Sign Up</button>
      </form>
      {error && <p>{error}</p>}
    </div>
  );
};

export default Signup;