import React, { useEffect, useState } from 'react';
import Photo from './Photo';
import firebase from './firebase';

const Gallery = () => {
  const [photos, setPhotos] = useState([]);

  useEffect(() => {
    // Fetch photos from Firestore collection
    const fetchPhotos = async () => {
      try {
        const photosCollection = await firebase.firestore().collection('images').get();
        const photosData = photosCollection.docs.map((doc) => {
          const { imageURL, imageName, username } = doc.data();
          const caption = `Posted by ${username}`;
          return {
            id: doc.id,
            imageURL,
            imageName,
            username,
            caption,
          };
        });
        setPhotos(photosData);
      } catch (error) {
        console.log(error);
      }
    };

    fetchPhotos();
  }, []);

  return (
    <div className="gallery">
      {photos.map((photo) => (
        <Photo key={photo.id} photo={photo} />
      ))}
    </div>
  );
};

export default Gallery;