import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/database';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';

const firebaseConfig = {
  apiKey: "AIzaSyB6WzMMtH6GOiwVvuOK3FHxMqRwW4zgYrQ",
  authDomain: "login-f6098.firebaseapp.com",
  projectId: "login-f6098",
  storageBucket: "login-f6098.appspot.com",
  messagingSenderId: "383454662060",
  appId: "1:383454662060:web:c3a2742971da0817733e09",
  measurementId: "G-FXHF3LFCDD"
};

firebase.initializeApp(firebaseConfig);

export default firebase;